var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded == 'OK')?_c('main',{staticClass:"container-flex",attrs:{"id":"projects"}},[_c('div',{staticClass:"row pl-0 pr-0 mr-0 ml-0"},_vm._l((_vm.filterPostsByEnabledAndSortByVar),function(item){return _c('a',{key:item._id,staticClass:"\n          col-sm-6 col-md-4 col-lg-3 col-xl-2 col-xxl-1 col-12\n          project-card-wrapper\n        ",attrs:{"id":item._id,"href":_vm.urlendpoint + item._id}},[_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(_vm.getPhoto(item.photo)),expression:"getPhoto(item.photo)",arg:"background-image"}],staticClass:"col-12 project-card",style:({
            'background-color': 'rgb(255, 255, 255)',
            /*
            'background-position': 'no-repeat',
            'background-size': 'scroll',
            'background-repeat': 'center',
            'background-origin': '/',
            'background-clip': 'cover',
*/
            
            'background-position': 'center',
            'background-repeat': 'no-repeat',
            'background-size': 'cover',
            'background-origin': 'padding-box, content-box',
        
            /*
            'background-position': 'center',
            'background-repeat': 'no-repeat',
            'background-size': 'cover / cover',
            //'background-image': 'rgb(255, 255, 255) url(' + getPhoto(item.photo) + ') no-repeat scroll left center / cover',
            'background-image': 'url(' + getPhoto(item.photo) + ') ',
            //'background-image': 'url(' + getPhoto(item.photo) + ') no-repeat scroll left center / cover',
            */

            /*
            background:
              'rgb(255, 255, 255) url(' +
              getPhoto(item.photo) +
              ') no-repeat scroll center / cover',
            */
          })},[_c('div',{staticClass:"project-card-content"},[_c('h4',{staticClass:"cardtitle"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"h-100 d-inline-block pb-3 card-metadata"},[(_vm.postSort == 'lastComment')?_c('div',[(item[_vm.postSort] == ['2020-11-30T00:00:00.000Z'])?_c('p'):_c('p',[_c('b-icon',{attrs:{"icon":"plus-circle","aria-hidden":"true"}}),_vm._v(" comentado "+_vm._s(_vm._f("moment")(item[_vm.postSort]))+" ")],1)]):(_vm.postSort == 'countOfComments')?_c('div',[_c('b-icon',{attrs:{"icon":"chat-left-text","aria-hidden":"true"}}),_vm._v(" "+_vm._s(item.countOfComments)+" comentarios ")],1):(_vm.postSort == 'updatedAt')?_c('div',[_c('p',[_c('b-icon',{attrs:{"icon":"chat-left-text","aria-hidden":"true"}}),_vm._v(" "+_vm._s(item.countOfComments)+" "),_c('b-icon',{attrs:{"icon":"pen","aria-hidden":"true"}}),_vm._v(" Publicado "+_vm._s(_vm._f("moment")(item.updatedAt))+" ")],1)]):_c('div',[_c('b-icon',{attrs:{"icon":"clock","aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm._f("moment")(item[_vm.postSort]))+" ")],1)])])])])}),0)]):_c('div',{staticClass:"idle-wrapper"},[(_vm.loaded == false)?_c('div',{staticClass:"loader"},[_c('b-spinner',{staticStyle:{"width":"4rem","height":"4rem"},attrs:{"variant":"primary"}})],1):(_vm.loaded == 'ERROR')?_c('div',{staticClass:"loadOrError"},[_vm._v("ERROR")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }