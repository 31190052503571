import { render, staticRenderFns } from "./App.vue?vue&type=template&id=670e8b26&scoped=true&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "vodal/common.css?vue&type=style&index=0&lang=css&"
import style1 from "vodal/rotate.css?vue&type=style&index=1&lang=css&"
import style2 from "./App.vue?vue&type=style&index=2&lang=scss&"
import style3 from "./App.vue?vue&type=style&index=3&lang=scss&"
import style4 from "./App.vue?vue&type=style&index=4&id=670e8b26&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "670e8b26",
  null
  
)

export default component.exports