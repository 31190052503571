<template>
  <div id="index">
    <Header :appName="appName"></Header>

    <vue-hcaptcha
      :sitekey="hcaptchatoken"
      @verify="onVerify"
      theme="dark"
    ></vue-hcaptcha>

    <!--
    // File upload
    <ik-upload
      :tags="['tag1', 'tag2']"
      :responseFields="['tags']"
      :onError="onErrorImagekit"
      :onSuccess="onSuccessImagekit"
      :useUniqueFileName="true"
      :isPrivateFile="false"
      customCoordinates="10,10,100,100"
    />
      <p>{{appName}}</p>
    -->
    <div>
      <div id="issue-embed"></div>
    </div>
  </div>
</template>

<script>
//import History from "./History";
//import { mapGetters } from "vuex";
import Header from "./Header";
import axios from "axios";
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
export default {
  
  mounted() {


    /**/
  },
  created() {
    const issueEmbed = document.createElement("script");
    issueEmbed.setAttribute(
      "src",
      "https://cdn.issueembed.dev/v1/issue-embed.min.js"
    );
    issueEmbed.async = true;
    document.head.appendChild(issueEmbed);
    issueEmbed.init("#issue-embed", {
      key: "dbb62ae9-ed4a-42a3-9dc0-ad7d5b23d7b8",
    });
  },
  metaInfo: {
    //title: process.env.VUE_APP_NAME + " | Comenta y postea anonimamente",
    meta: [
      {
        vmid: process.env.VUE_APP_NAME + " | Comenta y postea anonimamente",
        name: process.env.VUE_APP_NAME + " | Comenta y postea anonimamente",
        content: "Anoringa es un sitio en el que podras discutir anonimamente",
      },
      { charset: "utf-8" },
      {
        name: "description",
        content: "An example Vue application with vue-meta.",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
    // override the parent template and just use the above title only
    titleTemplate: false,
    htmlAttrs: {
      lang: "es-AR",
      amp: true,
    },
  },
  name: "Login",
  components: {
    //History,
    Header,
    VueHcaptcha,
  },
  data() {
    return {
      appName: "Anoringa",
      apiKey: "<YOUR_RAPIDAPI_KEY>",
      fromCurrency: "",
      toCurrency: "",
      amount: 0,
      result: 0,
      convertClicked: false,
      loading: false,
      hcaptchaResponse: "",
      loginurl: process.env.VUE_APP_API + "/api/user/register",
      hcaptchatoken: process.env.VUE_APP_HCAPTCHA,
      
    };
  },
  methods: {
    onErrorImagekit(err) {
      console.log("Error");
      console.log(err);
    },
    onSuccessImagekit(res) {
      console.log("Success");
      console.log(res);
    },
    clickConvert() {},
    convert() {},
    userStore(respuesta) {
      console.log(respuesta);
    },
    userCreate(hcaptchatoken) {
      var params = new URLSearchParams();
      params.append("token", hcaptchatoken);
      //axios.post("/foo", params);

      axios
        .post(this.loginurl, params)
        .then((response) => this.userStore(response))
        .catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log("error.response");
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log("error.request");
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    },
    onVerify(response) {
      console.log(response);
      this.hcaptchaResponse = response;
      this.userCreate(response);
      //this.$emit("event_child", this.hcaptchaResponse);
      //this.showModal = false;
      //emit()
      //createUser(this.hcaptchaResponse)

      /*
    axios
      .post("http://mediawiki.test:7070/api/auth", { token : this.hcaptchaResponse})
      .then((response) => console.log(response))
      .catch(function(error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
      */
    },
  },
};
</script>



<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Sen&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

$darker: #222;
$dark: #555;
$bright: #ddd;

#index {
  //width: 80%;
  margin: 0px;
  padding: 0px;
  text-align: center;
}
/*
html{
  margin: 0px;
  padding: 0px;
}
body{
  margin: 0px;
  padding: 0px;
}
body {
  margin: 0;
  color: $dark;
  background: $bright;
  font-family: "Segoe UI", "Roboto", Arial, Helvetica, sans-serif;
  font-size: 14px;
}

* {
  margin: 0;
  padding: 0;
}
#index {
  width: 80%;
  margin: auto;
  text-align: center;
  padding: 50px;
}
*/
</style>